import React from 'react';
import './App.css';
import PlayButton from './PlayButton';
import Blobity from "./blobity";

function App() {
  const [score, setScore] = React.useState(0);
  const [highScore, setHighScore] = React.useState(localStorage.getItem('highScore') || 0);
  const [currentSound, setCurrentSound] = React.useState(() => {
    return Math.random() < 0.5 ? 'defuse' : 'defuse2';
  });

  function optionClicked(input : string) {
    if (input === "Halfed Spike") {
      if (currentSound === 'defuse2') {
        increaseScore();
      } else {
        resetScore();
      }
    } else if (input === "Regular Spike") {
      if (currentSound === 'defuse') {
        increaseScore();
      } else {
        resetScore();
      }
    }
    setCurrentSound(getNewSound());
  }

  function increaseScore() {
    if (score + 1 > highScore) {
      let tempNumber : number = score + 1;
      setHighScore(tempNumber);
      localStorage.setItem('highScore', tempNumber.toString());
    }
    setScore(score + 1);
  }

  function resetScore() {
    if (score > highScore) {
        setHighScore(score);
        localStorage.setItem('highScore', score.toString());
    }
    setScore(0);
  }

  return (
    <div className="App">
      <Blobity />
      <header className="App-header">
        <h1>
          Valorant Spike Defuse Sound Test
        </h1>
        <h2>
          High Score 🏆 : {highScore}
        </h2>
        <h3>
          Score : {score}
        </h3>
        <PlayButton currentSound={currentSound} />
        <div>
          <button className={"OptionButton"} onClick={() => optionClicked("Halfed Spike")}>
            Halfed Spike
          </button>
          <button className={"OptionButton"} onClick={() => optionClicked("Regular Spike")}>
            Regular Spike
          </button>
        </div>
      </header>
    </div>
  );
}

function getNewSound() {
  return Math.random() < 0.5 ? 'defuse' : 'defuse2';
}

export default App;
