import useBlobity from 'blobity/lib/react/useBlobity';

const Blobity = () => {
    const blobity = useBlobity({
        focusableElementsOffsetX: 5,
        focusableElementsOffsetY: 5,
        color: '#666666',
        dotColor: '#ff33dd',
        invert: false,
        focusableElements: '[data-blobity], a:not([data-no-blobity]), button:not([data-no-blobity]), [data-blobity-tooltip], OptionButton',
        font: "'Montserrat','Source Sans Pro',-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif",
        fontSize: 14,
        fontWeight: 400,
        opacity: 1,
        fontColor: '#ffffff',
        zIndex: 0,
        size: 40,
        radius: 4,
    });

    return <div />;
};

export default Blobity;
