import useSound from 'use-sound';
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay, faStop} from "@fortawesome/free-solid-svg-icons";
// @ts-ignore
import firstAudioFile from './sound/valorant-defuse.mp3';
// @ts-ignore
import secondAudioFile from './sound/valorant-defuse2nd.mp3';

export default function PlayButton(props: any) {
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [playSoundDefuse, exposedData] = useSound(firstAudioFile);
    const [playSoundDefuse2, exposedData2] = useSound(secondAudioFile);

    let callback: NodeJS.Timeout = setTimeout(() => {});

    const Callback = (soundDuration : number) => {
        callback = setTimeout(function () {
            setIsPlaying(false);
        }, soundDuration);
    }

    const playSound = () => {
        if (props.currentSound === 'defuse') {
            let soundDuration : number = exposedData.duration? exposedData.duration : 0;
            playSoundDefuse();
            Callback(soundDuration);
        } else if (props.currentSound === 'defuse2') {
            let soundDuration : number = exposedData2.duration? exposedData2.duration : 0;
            playSoundDefuse2();
            Callback(soundDuration);
        }
    }

    const play = () => {
        if (!isPlaying) {
            playSound();
            setIsPlaying(true);
        } else {
            if (props.currentSound === 'defuse') {
                exposedData.stop();
            } else if (props.currentSound === 'defuse2') {
                exposedData2.stop();
            }
            clearTimeout(callback);
            setIsPlaying(false);
        }
    }

    return (
        <>
            <button className={"PlayButton"} onClick={play}>
                {isPlaying ? <FontAwesomeIcon icon={faStop} /> : <FontAwesomeIcon icon={faPlay} />}
            </button>
        </>

    )
}
